/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable, inject } from '@angular/core';
import {
  BASE_URL_API,
  ENV_TOKEN,
  EnvironmentModel,
  HttpService,
} from '@carwash-project/modules/core';
import { encryptFn } from '@carwash-project/modules/utils';
import { Observable } from 'rxjs';
import {
  ConfirmAccountModel,
  ForgotPasswordModel,
  IResetPasswordByAdmin,
  LoginModel,
  RegisterModel,
  ResetPasswordModel,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly baseApi: string = inject(BASE_URL_API);
  private readonly environment: EnvironmentModel = inject(ENV_TOKEN);
  private readonly httpService = inject(HttpService);
  private readonly pathAuth = `${this.baseApi}auth`;
  private readonly pathAuthAdmin = `${this.baseApi}authAdmin`;

  public login(body: LoginModel.Request): Observable<LoginModel.Response> {
    const url = `${this.pathAuth}/login`;
    const bodyEncrypt = encryptFn(
      JSON.stringify(body),
      this.environment.keyEncrypt
    );

    return this.httpService.post(
      url,
      {
        credentials: bodyEncrypt,
      },
      'Error al ingresar'
    );
  }

  public register(
    body: RegisterModel.Request
  ): Observable<RegisterModel.Response> {
    const url = `${this.pathAuth}/register`;
    return this.httpService.post(url, body, 'Error al registrar el usuario');
  }

  public confirmAccount(
    body: ConfirmAccountModel.Request
  ): Observable<ConfirmAccountModel.Response> {
    const url = `${this.pathAuth}/confirm-account`;
    return this.httpService.post(url, body, 'Error al confirmar la cuenta');
  }

  public ForgotPassword(
    body: ForgotPasswordModel.Request
  ): Observable<ForgotPasswordModel.Response> {
    const url = `${this.pathAuth}/forgot-password`;
    return this.httpService.post(url, body, 'Error al enviar E-mail.');
  }

  public ResetPassword(
    body: ResetPasswordModel.Request,
    token: string
  ): Observable<ResetPasswordModel.Response> {
    const url = `${this.pathAuth}/reset-password/${token}`;
    return this.httpService.post(url, body, 'Error al cambiar la contraseña.');
  }

  public ResetPasswordByAdmin(
    customerId: number | string
  ): Observable<IResetPasswordByAdmin> {
    const url = `${this.pathAuthAdmin}/forgot-password/${customerId}`;
    return this.httpService.post(url, null, 'Error al cambiar la contraseña.');
  }
}
