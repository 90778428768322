import {
  ConfirmAccountModel,
  ForgotPasswordModel,
  LoginModel,
  RegisterModel,
  ResetPasswordModel,
} from '../models';

export namespace AuthActions {
  export class Login {
    static readonly type = '[Auth] Login';
    constructor(public body: LoginModel.Request) {}
  }

  export class SessionID {
    static readonly type = '[Auth] Load session id';
    constructor(public id: string) {}
  }

  export class LoginSuccess {
    static readonly type = '[Auth] Login success';
  }

  export class Logout {
    static readonly type = '[Auth] Logout';
  }

  export class Register {
    static readonly type = '[Auth] Register user and clients';
    constructor(public body: RegisterModel.Request) {}
  }

  export class ConfirmAccount {
    static readonly type = '[Auth] Confirm Account';
    constructor(public body: ConfirmAccountModel.Request) {}
  }

  export class LoadStateAuth {
    static readonly type = '[Auth] Load state auth from local storage';
  }

  export class ForgotPassword {
    static readonly type = '[Auth] Forgot password';
    constructor(public body: ForgotPasswordModel.Request) {}
  }

  export class ResetPassword {
    static readonly type = '[Auth] Reset password';
    constructor(
      public body: ResetPasswordModel.Request,
      public token: string
    ) {}
  }

  export class ResetPasswordByAdmin {
    static readonly type = '[Auth] Reset password by admin';
    constructor(public customerId: number | string) {}
  }

  export class Failure {
    static readonly type = '[Auth] Failure Auth';
    constructor(public error: unknown) {}
  }
}
